import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { detect } from "detect-browser"

// Styles
import * as styles from "./VisitSpecificCafe.module.scss"

// Components
import PlaceholderImage from "../Shared/PlaceHolderImage"
import Image from "../Shared/Image"
import { imageSizes } from "../../helpers/imgix"

const textContentVariants = {
    visible: {
        opacity: 1,
        x: 0,
        transition: { delay: 0.5, duration: 0.3, ease: "easeOut" },
    },
    hidden: { opacity: 0, x: 50 },
}

// Unset initial transition state for IE
const isIeBrowser = /(ie)/i.test(detect().name)
if (isIeBrowser) {
    textContentVariants.hidden.opacity = 1
    textContentVariants.hidden.x = 0
}

const VisitSpecificCafe = (props) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("visible")
        }
    }, [controls, inView])

    return (
        <div className={styles.VisitSpecificCafe}>
            <div className={styles.image}>
                {props.content && props.content.image && <Image url={props.content.image.sourceUrl} alt={props.content.image.title} sizes={imageSizes.hero} />}
            </div>

            {props.content && (
                <motion.div className={styles.textContent} ref={ref} initial={typeof(window) !== 'object' ? 'visible' : 'hidden'} animate={controls} variants={textContentVariants}>
                    <div dangerouslySetInnerHTML={{ __html: props.content.description }}></div>
                    {props.content.download && (
                        <div className={styles.menuLink}>
                            <p>
                                <a href={props.content.download.url}>{props.content.downloadButtonText}</a>
                            </p>
                        </div>
                    )}
                </motion.div>
            )}
        </div>
    )
}

export default VisitSpecificCafe

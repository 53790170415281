import React from "react"
import GoogleMapReact from "google-map-react"
import Pin from "./Pin"

const Map = (props) => {
    const center = {
        lat: props.location.latitude,
        lng: props.location.longitude,
    }
    const zoom = 15

    return (
        <div style={{ height: "600px", width: "800px" }} className="googleMapIframe">
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyD9arsXDWSfrM0OXeQuSCnbI6-Izh3buy4" }}
                defaultCenter={center} defaultZoom={zoom} zoomControl={false}>
                <Pin lat={center.lat} lng={center.lng} />
            </GoogleMapReact>
        </div>
    )
}

export default Map

import React from "react"

const Pin = () => {
    const ICON = `M9.741,18.729C5.587,17.715 2.5,13.965 2.5,9.5C2.5,4.257 6.757,0 12,0C17.243,0 21.5,4.257 21.5,9.5C21.5,13.965 18.413,17.715 14.259,18.729L12,24L9.741,18.729Z`
    const pinStyle = {
        fill: "#E6007E",
        stroke: "none",
    }
    const size = 30

    return (
        <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
            <path d={ICON} />
        </svg>
    )
}

export default Pin

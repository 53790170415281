import gql from "graphql-tag"

export const getVenueVisitDetailPagePreview = gql`
    query getVenueVisitDetailPagePreview($id: ID!, $revision_id: Int!) {
        menus {
            results: nodes {
                id: menuId
                menuItems {
                    nodes {
                        id: menuItemId
                        label
                        url
                    }
                }
            }
        }
        page(id: $id, idType: DATABASE_ID) {
            title
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        link
                        slug
                        contentType {
                            node {
                                name
                            }
                        }
                        venue {
                            venue {
                                ... on Venue {
                                    id
                                    title
                                    venue_fields {
                                        addressAndOpeningTimes {
                                            openingTimes
                                            address
                                            image {
                                                sourceUrl
                                                title
                                            }
                                            download {
                                                url: mediaItemUrl
                                            }
                                            downloadButtonText
                                        }
                                        gettingHere {
                                            description
                                            location: map {
                                                latitude
                                                longitude
                                            }
                                            download {
                                                url: mediaItemUrl
                                            }
                                            downloadButtonText
                                        }
                                        facilitiesAndAccess {
                                            description
                                            image {
                                                sourceUrl
                                                title
                                            }
                                            download {
                                                url: mediaItemUrl
                                            }
                                            downloadButtonText
                                        }
                                        foodAndDrink {
                                            description
                                            image {
                                                sourceUrl
                                                title
                                            }
                                            download {
                                                url: mediaItemUrl
                                            }
                                            downloadButtonText
                                        }
                                        groupBookings {
                                            enabled
                                            title
                                            content
                                            link {
                                                ... on WPGraphQL_Page {
                                                    id
                                                    url: link
                                                    title
                                                }
                                            }
                                            linkText
                                        }
                                        logo {
                                            sourceUrl
                                            title
                                        }
                                        logoBackgroundColour
                                        venueDetailsLink {
                                            linkText
                                            link {
                                                url
                                                title
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        image: hero_fields {
                            image {
                                sourceUrl
                                title
                            }
                        }
                        page_introduction {
                            introduction
                        }
                        sub_title {
                            subTitle
                        }
                        footer_cta_options {
                            showPeople
                            title
                            description
                            people {
                                ... on Person {
                                    id
                                    title
                                    person_fields {
                                        ctaDescription
                                        email: emailAddress
                                        role
                                        phone: telephoneNumber
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

import React from "react"

// Styles
import * as styles from "./PersonCTA.module.scss"

// Componentsw
import PlaceholderImage from "./PlaceHolderImage"
import Image from "./Image"
import { imageSizes } from "../../helpers/imgix"

const PersonCTA = (props) => (
    <div className={styles.PersonCTA}>
        <div>
            {props.person_fields.image && <Image url={props.person_fields.image.sourceUrl} alt={props.person_fields.image.title} sizes={imageSizes.default} />}
        </div>
        <div className={styles.textContent}>
            <h3>Contact: {props.title}</h3>
            {props.person_fields.ctaDescription && <div className={styles.description}>{props.person_fields.ctaDescription}</div>}

            {props.person_fields.email && (
                <span className={styles.email}>
                    <a href={`mailto:${props.person_fields.email}`}>{props.person_fields.email}</a>
                </span>
            )}
            {props.person_fields.phone && (
                <span className={styles.phone}>
                    <a href={`tel:${props.person_fields.phone}`}>{props.person_fields.phone}</a>
                </span>
            )}
        </div>
    </div>
)

export default PersonCTA

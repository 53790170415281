import React from "react"

// Styles
import * as styles from "./VenueHireFooterCTA.module.scss"

// Components
import ContentWithSidebar from "./ContentWithSidebar"
import Content from "./Content"
import Sidebar from "./Sidebar"
import PersonCTA from "./PersonCTA"
import Article from "./Article"

const VenueHireFooterCTA = (props) => {
    const people = [].concat(props.people).map((person) => <PersonCTA key={person.id} {...person} />)

    return (
        <div className={styles.VenueHireFooterCTA}>
            <ContentWithSidebar>
                <Content>
                    <h1 className={styles.title}>{props.title}</h1>

                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: props.description }}></div>
                </Content>

                <Sidebar>{people}</Sidebar>
            </ContentWithSidebar>
        </div>
    )
}

export default VenueHireFooterCTA

import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getVenueVisitDetailPagePreview as PREVIEW_QUERY } from "../preview-queries/venueVisitDetailPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import VisitSpecificDirections from "../components/VisitSpecific/VisitSpecificDirections"
import VisitSpecificCafe from "../components/VisitSpecific/VisitSpecificCafe"
import VisitSpecificFacilities from "../components/VisitSpecific/VisitSpecificFacilities"
import VisitSpecificTimesAddress from "../components/VisitSpecific/VisitSpecificTimesAddress"
import VisitSpecificGroupBookings from "../components/VisitSpecific/VisitSpecificGroupBookings"
import SectionTitle from "../components/Shared/SectionTitle"
import HeroAreaLarge from "../components/Shared/HeroAreaLarge"
import { venueForPage } from "../helpers/venueForPage"
import VenueHireFooterCTA from "../components/Shared/VenueHireFooterCTA"
import SEOSettings from "../components/Shared/SEOSettings"

const groupBookingsFakeContent = {
    description:
        '<h3>Access</h3>\n<p>At Derby Museums we want everyone to be able to enjoy our exhibitions and events, and we have taken every measure to ensure that we are as open and accessible to as many people as possible. Read our access statement <a href="/accessibility/">here</a>.</p>\n<h3>Museum of Making floor plan</h3>\n<p>View a map of the Museum of Making <a href="https://cms.derbymuseums.org/wp-content/uploads/2021/06/DSM-Map_A4_Part1.pdf">here</a>.</p>\n<h3>Museum of Making Shop and Maker Shop</h3>\n<p>...',
    download: null,
    downloadButtonText: "Download",
    image: {
        sourceUrl: "https://assets.derbymuseums.org/2021/05/21031_Museum_of_Making_launch_photos_c-Chris-Seddon-Photography_March_2021_low_res_131.jpg",
        title: "21031_Museum_of_Making_launch_photos_[c] Chris Seddon Photography_March_2021_low_res_131",
    },
}

const TemplateVenueVisitDetail = (props) => {
    const page = getContent(props, "page")

    const venue = venueForPage({ page: page, menus: props.data.menus })
    const venueFields = page.venueFields.venue.venue_fields
    const superTitle = page.ancestors.nodes && page.ancestors.nodes.slice(-1)[0].title

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaLarge superTitle={superTitle} title={page.title} introduction={page.page_introduction.introduction} image={page.image.image} />

            <Main>
                <SectionTitle>Opening times</SectionTitle>
                <VisitSpecificTimesAddress content={venueFields.addressAndOpeningTimes} />
                <SectionTitle>Getting here</SectionTitle>
                <VisitSpecificDirections content={venueFields.gettingHere} />

                {venueFields.groupBookings && venueFields.groupBookings.enabled && (
                    <>
                        <SectionTitle>{venueFields.groupBookings.title}</SectionTitle>
                        <VisitSpecificGroupBookings content={venueFields.groupBookings} />
                    </>
                )}

                <SectionTitle>Facilities & access</SectionTitle>
                <VisitSpecificFacilities content={venueFields.facilitiesAndAccess} />
                <SectionTitle>Food & drink</SectionTitle>
                <VisitSpecificCafe content={venueFields.foodAndDrink} />

                {page.footer_cta_options && page.footer_cta_options.showPeople && <VenueHireFooterCTA {...page.footer_cta_options} />}
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query VenueVisitDetailPageQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            venueFields {
                venue {
                    ...VenueContent
                }
            }
            ...FooterCTASettings
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
            }
        
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(TemplateVenueVisitDetail)
